import React, { useState, useEffect, useRef } from 'react';
import { Button, Upload, Tooltip, message, Mentions } from 'antd';
import { SendOutlined, PaperClipOutlined, DeleteOutlined } from '@ant-design/icons';
import { initializeModelAliases } from '../../utils/modelConfig';
import { processWithModels } from '../../services/modelService';
import { useChat } from '../../contexts/ChatContext';

const ChatBox = () => {
  const [messageText, setMessageText] = useState('');
  const [isUploading, setIsUploading] = useState(false);
  const [modelAliases, setModelAliases] = useState([]);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [activeIndex, setActiveIndex] = useState(0);
  const [attachedFiles, setAttachedFiles] = useState([]);
  const mentionsRef = useRef(null);
  const { addUserMessage, addModelResponses, activeModels } = useChat();

  useEffect(() => {
    const loadModelAliases = () => {
      console.log('Raw localStorage data:', localStorage.getItem('model_aliases'));
      
      const models = initializeModelAliases();
      console.log('Models after initialization:', models);
      
      try {
        const allAliases = [];
        
        Object.entries(models).forEach(([provider, providerModels]) => {
          console.log(`Processing ${provider} models:`, providerModels);
          
          if (Array.isArray(providerModels)) {
            const providerAliases = providerModels
              .filter(model => model && model.alias)
              .map((model, modelIndex) => {
                const alias = {
                  key: `${provider}-${model.model}-${modelIndex}`,
                  value: model.alias.substring(1),
                  label: `${provider.toUpperCase()}: ${model.model} (${model.alias})`,
                  model: model.model,
                  provider: provider
                };
                console.log(`Created alias for ${provider}:`, alias);
                return alias;
              });
              
            console.log(`${provider} aliases:`, providerAliases);
            allAliases.push(...providerAliases);
          } else {
            console.warn(`${provider} models is not an array:`, providerModels);
          }
        });
        
        console.log('Final aliases array:', allAliases);
        
        setModelAliases(allAliases);
        setFilteredOptions(allAliases);
      } catch (error) {
        console.error('Error processing model aliases:', error);
        setModelAliases([]);
        setFilteredOptions([]);
      }
    };

    loadModelAliases();

    const handleStorageChange = (e) => {
      console.log('Storage change event:', e);
      if (e.key === 'model_aliases') {
        console.log('Reloading model aliases due to storage change');
        loadModelAliases();
      }
    };

    const handleModelUpdate = () => {
      console.log('Model aliases updated event received');
      loadModelAliases();
    };

    window.addEventListener('storage', handleStorageChange);
    window.addEventListener('modelAliasesUpdated', handleModelUpdate);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      window.removeEventListener('modelAliasesUpdated', handleModelUpdate);
    };
  }, []);

  const handleFileUpload = ({ file }) => {
    // Handle file size limit (10MB)
    const maxSize = 10 * 1024 * 1024; // 10MB in bytes
    if (file.size > maxSize) {
      message.error('File size must be less than 10MB');
      return;
    }

    // Verify file is an image
    if (!file.type.startsWith('image/')) {
      message.error('Only image files are supported');
      return;
    }

    // Create a new file object with additional properties
    const newFile = {
      uid: file.uid,
      name: file.name,
      type: file.type,
      size: file.size,
      originFileObj: file
    };

    // Create preview URL for image
    const reader = new FileReader();
    reader.onload = () => {
      newFile.previewUrl = reader.result;
      newFile.content = reader.result; // This will be used as the image_url
      setAttachedFiles(prevFiles => [...prevFiles, newFile]);
    };
    reader.readAsDataURL(file);

    message.success(`${file.name} attached successfully`);
  };

  const removeFile = (fileToRemove) => {
    setAttachedFiles(prevFiles => 
      prevFiles.filter(file => file.uid !== fileToRemove.uid)
    );
    
    if (fileToRemove.previewUrl) {
      URL.revokeObjectURL(fileToRemove.previewUrl);
    }
  };

  const renderFilePreview = (file) => (
    <div className="file-preview image-preview" key={file.uid}>
      <img src={file.previewUrl} alt={file.name} />
      <Button 
        type="text" 
        icon={<DeleteOutlined />} 
        onClick={() => removeFile(file)}
        className="remove-file"
      />
    </div>
  );

  const handleSend = async () => {
    if (!messageText.trim() && attachedFiles.length === 0) return;
    
    const words = messageText.split(' ');
    const mentionedModels = [];
    const messageContent = words.filter(word => {
      if (word.startsWith('@')) {
        const alias = word.substring(1);
        const model = modelAliases.find(m => m.value === alias);
        if (model) {
          mentionedModels.push('@' + model.value);
          return false;
        }
      }
      return true;
    }).join(' ');

    const targetModels = mentionedModels.length > 0 ? mentionedModels : Array.from(activeModels);

    if (targetModels.length === 0) {
      message.error('Please mention at least one model using @');
      return;
    }

    // Create display message content
    const displayContent = messageContent + 
      (attachedFiles.length > 0 ? 
        '\n\nAttached images:\n' + 
        attachedFiles.map(file => `- ${file.name}`).join('\n')
        : '');

    // Prepare content for API
    const apiContent = {
      text: messageContent,
      files: attachedFiles.map(file => ({
        name: file.name,
        type: file.type,
        content: file.content,
        size: file.size
      }))
    };

    setMessageText('');
    setAttachedFiles([]);

    try {
      addUserMessage(displayContent, targetModels);
      const responses = await processWithModels(apiContent, targetModels);
      addModelResponses(responses);
    } catch (error) {
      console.error('Error processing message:', error);
      message.error('Failed to process message with some models');
    }
  };

  const completeModelMention = (matchingOption) => {
    const lastAtIndex = messageText.lastIndexOf('@');
    if (lastAtIndex === -1) return;

    const currentInput = messageText.slice(lastAtIndex + 1).split(' ')[0];
    const beforeAt = messageText.slice(0, lastAtIndex);
    const afterPartialMention = messageText.slice(lastAtIndex + currentInput.length + 1);
    
    const newText = beforeAt + '@' + matchingOption.value + ' ' + afterPartialMention;
    
    setMessageText(newText);
    setActiveIndex(0);

    if (mentionsRef.current) {
      const textArea = mentionsRef.current.textarea;
      if (textArea) {
        textArea.blur();
        requestAnimationFrame(() => {
          textArea.focus();
          textArea.setSelectionRange(newText.length, newText.length);
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (dropdownVisible) {
      if (e.key === 'ArrowDown') {
        e.preventDefault();
        setActiveIndex(prev => {
          const next = (prev + 1) % filteredOptions.length;
          scrollToOption(next);
          return next;
        });
      } else if (e.key === 'ArrowUp') {
        e.preventDefault();
        setActiveIndex(prev => {
          const next = prev === 0 ? filteredOptions.length - 1 : prev - 1;
          scrollToOption(next);
          return next;
        });
      } else if (e.key === 'Tab' || e.key === 'Enter') {
        e.preventDefault();
        const matchingOption = filteredOptions[activeIndex];
        if (matchingOption) {
          setDropdownVisible(false);
          setFilteredOptions([]);
          requestAnimationFrame(() => {
            completeModelMention(matchingOption);
          });
        }
      } else if (e.key === 'Escape') {
        e.preventDefault();
        setDropdownVisible(false);
        setFilteredOptions([]);
        setActiveIndex(0);
      }
    } else if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSend();
    }
  };

  const scrollToOption = (index) => {
    setTimeout(() => {
      const dropdown = document.querySelector('.ant-mentions-dropdown');
      if (dropdown) {
        const items = dropdown.querySelectorAll('.ant-mentions-dropdown-menu-item');
        if (items[index]) {
          items[index].scrollIntoView({
            block: 'nearest',
            behavior: 'smooth'
          });
        }
      }
    }, 0);
  };

  const handleChange = (value) => {
    setMessageText(value);
    const lastAtIndex = value.lastIndexOf('@');
    
    if (lastAtIndex !== -1) {
      const textAfterAt = value.slice(lastAtIndex + 1);
      const isSelectedModel = textAfterAt.split(' ')[0] && 
        modelAliases.some(m => m.value === textAfterAt.split(' ')[0]);

      if (!isSelectedModel) {
        if (lastAtIndex === value.length - 1) {
          setFilteredOptions(modelAliases);
          setDropdownVisible(true);
        } else if (!textAfterAt.includes(' ')) {
          const search = textAfterAt.toLowerCase();
          const filtered = modelAliases.filter(option => 
            option.label.toLowerCase().includes(search) ||
            option.value.toLowerCase().includes(search)
          );
          setFilteredOptions(filtered);
          setDropdownVisible(true);
        } else {
          setDropdownVisible(false);
        }
      } else {
        setDropdownVisible(false);
      }
    } else {
      setDropdownVisible(false);
    }
  };

  const handleSelect = (option) => {
    completeModelMention(option);
  };

  return (
    <div className="chat-box">
      {attachedFiles.length > 0 && (
        <div className="attached-files">
          {attachedFiles.map(file => renderFilePreview(file))}
        </div>
      )}
      <div className="chat-box-wrapper">
        <Upload
          className="upload-button"
          beforeUpload={(file) => {
            handleFileUpload({ file });
            return false; // Prevent actual upload
          }}
          showUploadList={false}
          multiple={true}
          accept="image/*"
        >
          <Tooltip title="Attach images">
            <Button 
              type="text" 
              icon={<PaperClipOutlined />}
              loading={isUploading}
            />
          </Tooltip>
        </Upload>

        <Mentions
          ref={mentionsRef}
          value={messageText}
          onChange={handleChange}
          onSelect={handleSelect}
          onKeyDown={handleKeyDown}
          placeholder={activeModels.size > 0 ? "Continue the conversation..." : "Type @ to mention models (e.g., @gpt4o What's in this image?)"}
          autoSize={{ minRows: 1, maxRows: 4 }}
          className="chat-input"
          options={filteredOptions.map((option, index) => ({
            ...option,
            key: `${option.provider}-${option.model}-${index}`
          }))}
          prefix="@"
          split=" "
          placement="top"
          validateSearch={(search) => true}
          style={{ width: '100%' }}
          dropdownClassName="mentions-dropdown"
          notFoundContent={
            dropdownVisible && filteredOptions.length === 0 ? "No matching models" : null
          }
          status={dropdownVisible ? 'open' : undefined}
        />

        <Tooltip title="Send message">
          <Button
            type="primary"
            icon={<SendOutlined />}
            onClick={handleSend}
            disabled={!messageText.trim() && attachedFiles.length === 0}
            className="send-button"
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default ChatBox;