import { Layout } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined, CoffeeOutlined } from '@ant-design/icons';
import { useTheme } from '../../hooks/useTheme';

const Sidebar = ({ collapsed, onCollapse }) => {
  const { currentFont } = useTheme();

  const handleDonateClick = () => {
    window.open('https://buymeacoffee.com/builderarjan', '_blank');
  };

  const handleFeedbackClick = () => {
    window.open('https://x.com/arjan2025', '_blank');
  };

  return (
    <Layout.Sider 
      trigger={null} 
      collapsible 
      collapsed={collapsed}
      width={260}
      className="sidebar"
    >
      <div className="sidebar-inner" style={{ fontFamily: currentFont }}>
        {/* Sidebar Header */}
        <div className="sidebar-header">
          {!collapsed && <span className="sidebar-logo">EveryGPT</span>}
          <button
            className="sidebar-toggle"
            onClick={() => onCollapse(!collapsed)}
          >
            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          </button>
        </div>

        {/* Sidebar Content */}
        <div className="sidebar-content">
          {/* Menu items will go here */}
        </div>

        {/* Sidebar Footer */}
        <div className="sidebar-footer">
          {!collapsed && (
            <>
              <div className="footer-text">
                AI models can make mistakes. Check important info.
              </div>
              <div className="social-buttons">
                <button 
                  className="donate-button" 
                  onClick={handleDonateClick}
                >
                  <CoffeeOutlined /> Support
                </button>
              </div>
              <div className="action-buttons">
                <button 
                  className="feedback-button" 
                  onClick={handleFeedbackClick}
                >
                  <svg className="x-icon" viewBox="0 0 24 24">
                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                  </svg>
                  Report Bug / Request Feature
                </button>
              </div>
            </>
          )}
          {collapsed && (
            <div className="social-buttons-collapsed">
              <button 
                className="donate-button-collapsed" 
                onClick={handleDonateClick}
                title="Support on Buy Me a Coffee"
              >
                <CoffeeOutlined />
              </button>
              <button 
                className="feedback-button-collapsed" 
                onClick={handleFeedbackClick}
                title="Report bug or request feature"
              >
                <svg className="x-icon" viewBox="0 0 24 24">
                  <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
                </svg>
              </button>
            </div>
          )}
        </div>
      </div>
    </Layout.Sider>
  );
};

export default Sidebar;