import React, { useState, useEffect } from 'react';
import { Input, Button, Form, message } from 'antd';
import SettingSection from '../ui/SettingSection';
import SettingOption from '../ui/SettingOption';

const ApiKeySettings = () => {
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [changedFields, setChangedFields] = useState({});

  useEffect(() => {
    // Load saved API keys
    const savedKeys = {
      openai_api_key: localStorage.getItem('openai_api_key') || '',
      anthropic_api_key: localStorage.getItem('anthropic_api_key') || '',
      gemini_api_key: localStorage.getItem('gemini_api_key') || ''
    };
    form.setFieldsValue(savedKeys);
  }, [form]);

  const handleValuesChange = (changedValues) => {
    setIsFormChanged(true);
    setChangedFields(prev => ({
      ...prev,
      ...Object.keys(changedValues).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    }));
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      
      // Save API keys to localStorage
      Object.entries(values).forEach(([key, value]) => {
        if (value) {
          localStorage.setItem(key, value);
        } else {
          localStorage.removeItem(key);
        }
      });

      setIsFormChanged(false);
      setChangedFields({});
      message.success('API keys saved successfully');
    } catch (error) {
      message.error('Please check your inputs');
    }
  };

  const labelStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    gap: '16px', // Add space between model name and link
    padding: '4px 0' // Add vertical padding
  };

  const linkStyle = {
    fontSize: '14px',
    whiteSpace: 'nowrap', // Prevent link from wrapping
    color: '#1677ff' // Using the theme's primary blue color
  };

  return (
    <div className="settings-section">
      <Form 
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <SettingSection title="API Keys">
          <SettingOption>
            <Form.Item
              name="openai_api_key"
              label={
                <div style={labelStyle}>
                  <span>OpenAI API Key</span>
                  <a 
                    href="https://platform.openai.com/api-keys" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    Get API Key
                  </a>
                </div>
              }
              rules={[{ required: false }]}
              className={changedFields.openai_api_key ? 'changed-field' : ''}
            >
              <Input.Password placeholder="Enter OpenAI API key" />
            </Form.Item>
          </SettingOption>

          <SettingOption>
            <Form.Item
              name="anthropic_api_key"
              label={
                <div style={labelStyle}>
                  <span>Anthropic API Key</span>
                  <a 
                    href="https://console.anthropic.com/settings/keys" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    Get API Key
                  </a>
                </div>
              }
              rules={[{ required: false }]}
              className={changedFields.anthropic_api_key ? 'changed-field' : ''}
            >
              <Input.Password placeholder="Enter Anthropic API key" />
            </Form.Item>
          </SettingOption>

          <SettingOption>
            <Form.Item
              name="gemini_api_key"
              label={
                <div style={labelStyle}>
                  <span>Google Gemini API Key</span>
                  <a 
                    href="https://aistudio.google.com/app/apikey" 
                    target="_blank" 
                    rel="noopener noreferrer"
                    style={linkStyle}
                  >
                    Get API Key
                  </a>
                </div>
              }
              rules={[{ required: false }]}
              className={changedFields.gemini_api_key ? 'changed-field' : ''}
            >
              <Input.Password placeholder="Enter Gemini API key" />
            </Form.Item>
          </SettingOption>
        </SettingSection>

        <div className="settings-footer">
          <Button 
            type="primary" 
            onClick={handleSave}
            disabled={!isFormChanged}
          >
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ApiKeySettings; 