import { Layout } from 'antd';
import MainHeader from './MainHeader';
import MainContent from './MainContent';

const MainLayout = ({ collapsed }) => {
  return (
    <Layout 
      className={`main-layout ${collapsed ? 'sidebar-collapsed' : ''}`}
    >
      <MainHeader />
      <MainContent />
    </Layout>
  );
};

export default MainLayout;