import React from 'react';
import SettingSection from '../ui/SettingSection';

const PrivacySettings = () => {
  return (
    <div className="settings-section">
      <SettingSection title="Privacy & Data">
        <div className="privacy-message" style={{ color: 'var(--text-primary)' }}>
          <p style={{ marginBottom: '16px' }}>
            EveryGPT is a client-only application that prioritizes your privacy:
          </p>
          <ul style={{ listStyle: 'disc', paddingLeft: '20px', lineHeight: '1.6' }}>
            <li>All data stays in your browser - we don't save or record anything</li>
            <li>Your API keys and settings are stored only in your browser's local storage</li>
            <li>Your conversations are not saved between sessions</li>
            <li>No server tracking or analytics - complete privacy</li>
          </ul>
        </div>
      </SettingSection>
    </div>
  );
};

export default PrivacySettings; 