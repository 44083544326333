import React, { useEffect, useRef } from 'react';
import { CloseOutlined, SettingOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import ChatBox from '../chat/ChatBox';
import MessageContent from '../chat/MessageContent';
import { useChat } from '../../contexts/ChatContext';
import '../../styles/layout.css';
import '../../styles/code.css';

const { Title, Text } = Typography;

const MainContent = () => {
  const { messages, loadingModels, removeModelThread, modelErrors } = useChat();
  const threadRefs = useRef({});

  // Scroll specific thread to bottom
  const scrollThreadToBottom = (modelId) => {
    if (threadRefs.current[modelId]) {
      const threadElement = threadRefs.current[modelId];
      threadElement.scrollTop = threadElement.scrollHeight;
    }
  };

  // Scroll when messages change
  useEffect(() => {
    messages.forEach(message => {
      if (message.type === 'model') {
        scrollThreadToBottom(message.model);
      }
    });
  }, [messages]);

  const renderConversation = (conversation, modelId) => {
    const uniqueMessages = conversation.reduce((acc, curr) => {
      const key = `${curr.role}-${curr.content}-${curr.timestamp.getTime()}`;
      if (!acc.some(msg => 
        msg.role === curr.role && 
        msg.content === curr.content && 
        msg.timestamp.getTime() === curr.timestamp.getTime()
      )) {
        acc.push(curr);
      }
      return acc;
    }, []);

    return uniqueMessages.map((message, index) => (
      <div key={`${message.role}-${message.timestamp.getTime()}-${index}`} 
           className={`conversation-message ${message.role} ${message.error ? 'error' : ''}`}>
        <div className="message-timestamp">
          {message.timestamp.toLocaleTimeString()}
        </div>
        {message.role === 'user' ? (
          <div className="message-content">
            <strong>User: </strong>
            {message.content}
          </div>
        ) : message.error ? (
          <div className="message-content error">
            <div className="error-icon">⚠️</div>
            <div className="error-message">{message.content}</div>
          </div>
        ) : (
          <MessageContent content={message.content} />
        )}
      </div>
    ));
  };

  const handleCloseBox = (modelId) => {
    removeModelThread(modelId);
  };

  return (
    <div className="main-content">
      <div className="content-wrapper chat-messages">
        {messages.length > 0 ? (
          <div className="messages-container">
            {messages.map((message, index) => {
              if (message.type === 'model') {
                const isLoading = loadingModels.has(message.model);
                const error = modelErrors.get(message.model);
                
                return (
                  <React.Fragment key={`${message.model}-${message.timestamp.getTime()}`}>
                    <div className={`message-box ${isLoading ? 'loading' : ''} ${error ? 'error' : ''}`}>
                      <div className="message-header">
                        <span className="model-name">{message.model}</span>
                        <button 
                          className="close-button"
                          onClick={() => handleCloseBox(message.model)}
                          aria-label="Close chat"
                        >
                          <CloseOutlined />
                        </button>
                      </div>
                      <div 
                        className="conversation-thread"
                        ref={el => threadRefs.current[message.model] = el}
                      >
                        {renderConversation(message.conversation, message.model)}
                        {isLoading && !error && (
                          <div className="loading-indicator">
                            <div className="typing-dots">
                              <span></span>
                              <span></span>
                              <span></span>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                    {index < messages.length - 1 && <div className="message-separator" />}
                  </React.Fragment>
                );
              }
              return null;
            })}
          </div>
        ) : (
          <div className="empty-state" style={{ 
            textAlign: 'center', 
            maxWidth: '600px', 
            margin: '40px auto',
            padding: '40px 20px',
            background: 'var(--bg-secondary)',
            borderRadius: '12px',
            border: '1px solid var(--border-color)'
          }}>
            <Title level={3} style={{ marginBottom: '24px', color: 'var(--text-primary)' }}>
              Welcome to EveryGPT!
            </Title>
            <Text style={{ 
              fontSize: '16px', 
              color: 'var(--text-primary)',
              display: 'block',
              marginBottom: '24px',
              lineHeight: '1.6'
            }}>
              To get started:
            </Text>
            <div style={{ 
              textAlign: 'left', 
              marginBottom: '32px',
              background: 'var(--bg-primary)',
              padding: '24px',
              borderRadius: '8px',
              border: '1px solid var(--border-color)'
            }}>
              <ol style={{ 
                paddingLeft: '20px',
                margin: 0,
                color: 'var(--text-primary)',
                lineHeight: '2'
              }}>
                <li>Click the <SettingOutlined /> icon and add your API keys in the Settings</li>
                <li>Type @ in the chat box below to mention models (e.g., "@gpt4o @claude")</li>
                <li>Ask your question (e.g., "What's the best way to learn programming?")</li>
              </ol>
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '12px' }}>
              <Button 
                type="primary" 
                icon={<SettingOutlined />}
                onClick={() => document.querySelector('.header-action-button').click()}
              >
                Open Settings
              </Button>
              <Button 
                icon={<QuestionCircleOutlined />}
                onClick={() => document.querySelectorAll('.header-action-button')[0].click()}
              >
                Read Help Guide
              </Button>
            </div>
          </div>
        )}
      </div>
      <ChatBox />
    </div>
  );
};

export default MainContent;