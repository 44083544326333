const CODE_BLOCK_REGEX = /```(\w+)?\n([\s\S]*?)```/g;
const INLINE_CODE_REGEX = /`([^`]+)`/g;

export const parseMessageContent = (content) => {
  const parts = [];
  let lastIndex = 0;

  // Find all code blocks
  for (const match of content.matchAll(CODE_BLOCK_REGEX)) {
    // Add text before the code block
    if (match.index > lastIndex) {
      const text = content.slice(lastIndex, match.index);
      parts.push({ type: 'text', content: text });
    }

    // Add the code block
    parts.push({
      type: 'code-block',
      language: match[1] || 'plaintext',
      content: match[2].trim()
    });

    lastIndex = match.index + match[0].length;
  }

  // Add remaining text
  if (lastIndex < content.length) {
    const text = content.slice(lastIndex);
    parts.push({ type: 'text', content: text });
  }

  return parts;
};

export const formatInlineCode = (text) => {
  return text.replace(INLINE_CODE_REGEX, (match, code) => {
    return `<code>${code}</code>`;
  });
}; 