// src/components/layout/MainHeader.jsx
import { QuestionCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Tooltip, Switch, Modal, Typography } from 'antd';
import { useContext, useState } from 'react';
import { ThemeContext } from '../../contexts/ThemeContext';
import Settings from '../settings/Settings';

const { Title, Paragraph, Text } = Typography;

const MainHeader = () => {
  const { isDarkMode, toggleTheme } = useContext(ThemeContext);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const handleXClick = () => {
    window.open('https://x.com/arjan2025', '_blank');
  };

  const ExampleConversation = () => (
    <div className="example-conversation">
      <div className="example-message user">
        <Text>@gpt4o @claude What are the best cities in the world?</Text>
      </div>
      <div className="example-message model">
        <Text strong>GPT-4:</Text>
        <Text>1. Tokyo - Technology and tradition
2. Copenhagen - Sustainability leader
3. Melbourne - Most livable city
4. Singapore - Innovation hub
5. Vancouver - Natural beauty meets urban life</Text>
      </div>
      <div className="example-message model">
        <Text strong>Claude:</Text>
        <Text>1. Vienna - Cultural capital
2. Zurich - Quality of life
3. Amsterdam - Bike-friendly
4. Barcelona - Architecture
5. Seoul - Smart city pioneer</Text>
      </div>
    </div>
  );

  return (
    <>
      <div className="main-header">
        <div className="header-left">
          <div className="header-title">EveryGPT</div>
        </div>
        <div className="header-right">
          <div className="header-actions">
            <Tooltip title={isDarkMode ? "Light Mode" : "Dark Mode"}>
              <Switch
                checked={isDarkMode}
                onChange={toggleTheme}
                className="theme-switch"
              />
            </Tooltip>
            <Tooltip title="Help">
              <Button 
                type="text" 
                icon={<QuestionCircleOutlined />} 
                className="header-action-button"
                onClick={() => setIsHelpOpen(true)}
              />
            </Tooltip>
            <Tooltip title="Settings">
              <Button 
                type="text" 
                icon={<SettingOutlined />} 
                className="header-action-button"
                onClick={() => setIsSettingsOpen(true)}
              />
            </Tooltip>
          </div>
        </div>
      </div>

      <Modal
        title="Settings"
        open={isSettingsOpen}
        onCancel={() => setIsSettingsOpen(false)}
        width={800}
        footer={null}
        className="settings-modal"
      >
        <Settings onClose={() => setIsSettingsOpen(false)} />
      </Modal>

      <Modal
        title="How to Use EveryGPT"
        open={isHelpOpen}
        onCancel={() => setIsHelpOpen(false)}
        width={800}
        footer={[
          <Button 
            key="x-button" 
            type="primary" 
            onClick={handleXClick}
            className="x-help-button"
          >
            <svg className="x-icon" viewBox="0 0 24 24">
              <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z"/>
            </svg>
            Have questions? Reach out on X
          </Button>
        ]}
        className="help-modal"
      >
        <div className="help-content">
          <Title level={4}>About EveryGPT</Title>
          <Paragraph>
            EveryGPT allows you to chat with multiple AI models simultaneously. Simply mention models using their aliases to get responses from different AIs at once.
          </Paragraph>

          <Title level={4}>Current Features</Title>
          <Paragraph>
            <ul>
              <li>Chat with multiple models simultaneously</li>
              <li>Support for image inputs</li>
              <li>Customizable model aliases</li>
            </ul>
          </Paragraph>

          <Title level={4}>Coming Soon</Title>
          <Paragraph>
            <ul>
              <li>Context-aware conversations</li>
              <li>More model integrations</li>
              <li>Enhanced multimedia support</li>
            </ul>
          </Paragraph>

          <Title level={4}>Privacy & Data</Title>
          <Paragraph>
            EveryGPT is a client-only application, which means:
            <ul>
              <li>All data stays in your browser - we don't save or record anything</li>
              <li>Your API keys and settings are stored only in your browser's local storage</li>
              <li>Your conversations are not saved between sessions</li>
              <li>No server tracking or analytics - complete privacy</li>
            </ul>
          </Paragraph>

          <Title level={4}>Settings Overview</Title>
          <Paragraph>
            The settings panel has two main sections:
            <ul>
              <li><strong>Keys:</strong> Manage your API keys for different AI providers</li>
              <li><strong>Models:</strong> Customize model aliases and configure which models to use</li>
            </ul>
          </Paragraph>

          <Title level={4}>API Key Setup</Title>
          <Paragraph>
            To use EveryGPT, you need to set up your API keys in the settings:
            <ul>
              <li>Click the settings icon in the top-right corner</li>
              <li>Add your API keys for the models you want to use (OpenAI, Anthropic, Google)</li>
              <li>Your API keys are stored securely in your browser's local storage</li>
              <li>Without API keys, the models won't be able to respond to your messages</li>
            </ul>
          </Paragraph>

          <Title level={4}>How to Use Model Aliases</Title>
          <Paragraph>
            Use the @ symbol followed by a model alias to mention models. Here are the rules:
            <ul>
              <li>At the start: No space needed (e.g., "@gpt4o Hello")</li>
              <li>In the middle: Spaces required (e.g., "Hi @gpt4o how are you")</li>
              <li>Multiple models: Space between aliases (e.g., "@gpt4o @claude Hello")</li>
              <li>Invalid format: No space after @ (e.g., "@claudeHello" won't work)</li>
            </ul>
          </Paragraph>

          <Title level={4}>Example Conversation</Title>
          <ExampleConversation />
        </div>
      </Modal>
    </>
  );
};

export default MainHeader;