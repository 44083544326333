import React from 'react';
import CodeBlock from './CodeBlock';
import { parseMessageContent, formatInlineCode } from '../../utils/codeFormatter';
import DOMPurify from 'dompurify';

const MessageContent = ({ content }) => {
  const parts = parseMessageContent(content);

  return (
    <div className="message-content">
      {parts.map((part, index) => {
        if (part.type === 'code-block') {
          return (
            <CodeBlock
              key={index}
              code={part.content}
              language={part.language}
            />
          );
        } else {
          // Format inline code and sanitize HTML
          const formattedText = formatInlineCode(part.content);
          const sanitizedHtml = DOMPurify.sanitize(formattedText);
          return (
            <div
              key={index}
              dangerouslySetInnerHTML={{ __html: sanitizedHtml }}
            />
          );
        }
      })}
    </div>
  );
};

export default MessageContent; 