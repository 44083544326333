const defaultModels = {
  openai: [
    { 
      model: 'gpt-4o', 
      alias: '@gpt4o',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image'],
        outputTypes: ['text']
      }
    },
    { 
      model: 'gpt-4o-mini', 
      alias: '@gpt4omini',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image'],
        outputTypes: ['text']
      }
    },
    { 
      model: 'gpt-4-turbo', 
      alias: '@gpt4turbo',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image'],
        outputTypes: ['text']
      }
    },
    { 
      model: 'gpt-4', 
      alias: '@gpt4',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image'],
        outputTypes: ['text']
      }
    },
    { model: 'gpt-3.5-turbo', alias: '@gpt3' }
  ],
  anthropic: [
    { model: 'claude-3-opus-20240229', alias: '@claude3' },
    { model: 'claude-3-sonnet-20240229', alias: '@sonnet' },
    { model: 'claude-2.1', alias: '@claude2' }
  ],
  gemini: [
    { 
      model: 'gemini-1.5-pro', 
      alias: '@gemini',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image', 'audio', 'video'],
        outputTypes: ['text']
      }
    },
    { 
      model: 'gemini-1.5-flash', 
      alias: '@flash',
      capabilities: {
        multimodal: true,
        acceptsTypes: ['text', 'image', 'audio', 'video'],
        outputTypes: ['text']
      }
    }
  ]
};

export const initializeModelAliases = () => {
  const savedModels = localStorage.getItem('model_aliases');
  if (!savedModels) {
    localStorage.setItem('model_aliases', JSON.stringify(defaultModels));
    return defaultModels;
  }

  // Parse saved models and ensure all required providers and models exist
  const parsedModels = JSON.parse(savedModels);
  const mergedModels = {};

  // Start with default models structure
  Object.entries(defaultModels).forEach(([provider, defaultProviderModels]) => {
    mergedModels[provider] = defaultProviderModels.map((defaultModel, index) => {
      const savedModel = parsedModels[provider]?.[index];
      if (savedModel) {
        // Preserve saved model data but ensure model name and capabilities from default
        return {
          ...defaultModel,
          alias: savedModel.alias // Only take the alias from saved data
        };
      }
      return defaultModel;
    });
  });

  // Save the merged models back to localStorage
  localStorage.setItem('model_aliases', JSON.stringify(mergedModels));
  return mergedModels;
};

export { defaultModels }; 