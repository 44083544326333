import React, { useState, useEffect } from 'react';
import { Tabs, Input, Button, Form, message } from 'antd';
import SettingSection from '../ui/SettingSection';
import SettingOption from '../ui/SettingOption';
import { defaultModels } from '../../../utils/modelConfig';

const ModelsSettings = () => {
  const [form] = Form.useForm();
  const [isFormChanged, setIsFormChanged] = useState(false);
  const [changedFields, setChangedFields] = useState({});
  const [modelConfig, setModelConfig] = useState(null);

  useEffect(() => {
    // Load saved model configurations from localStorage
    const savedModels = localStorage.getItem('model_aliases');
    const initialConfig = savedModels ? JSON.parse(savedModels) : defaultModels;
    setModelConfig(initialConfig);
    
    // Convert to form values format, but only for aliases
    const formValues = Object.entries(initialConfig).reduce((acc, [provider, models]) => {
      models.forEach((model, index) => {
        acc[`${provider}_alias_${index}`] = model.alias;
      });
      return acc;
    }, {});
    
    form.setFieldsValue(formValues);
  }, [form]);

  const handleValuesChange = (changedValues) => {
    setIsFormChanged(true);
    setChangedFields(prev => ({
      ...prev,
      ...Object.keys(changedValues).reduce((acc, key) => {
        acc[key] = true;
        return acc;
      }, {})
    }));
  };

  const handleSave = async () => {
    try {
      console.log('Starting save process...');
      const values = await form.validateFields();
      console.log('Form values:', values);
      
      // Get current state from localStorage
      const currentConfig = JSON.parse(localStorage.getItem('model_aliases')) || defaultModels;
      console.log('Current config from localStorage:', currentConfig);
      
      // Create a deep copy of the current config
      const updatedConfig = JSON.parse(JSON.stringify(currentConfig));
      
      // Update only the aliases
      Object.entries(updatedConfig).forEach(([provider, models]) => {
        console.log(`Updating ${provider} models...`);
        if (Array.isArray(models)) {
          models.forEach((model, index) => {
            const newAlias = values[`${provider}_alias_${index}`];
            console.log(`Setting ${provider} model ${index} alias to:`, newAlias);
            if (model && newAlias) {
              model.alias = newAlias;
            }
          });
        }
      });

      console.log('Final updated config:', updatedConfig);
      
      // Save to localStorage
      localStorage.setItem('model_aliases', JSON.stringify(updatedConfig));
      console.log('Saved to localStorage');
      
      // Update component state
      setModelConfig(updatedConfig);
      setIsFormChanged(false);
      setChangedFields({});
      
      // Notify other components
      console.log('Dispatching modelAliasesUpdated event');
      window.dispatchEvent(new Event('modelAliasesUpdated'));
      
      message.success('Model aliases saved successfully');
    } catch (error) {
      console.error('Error saving model aliases:', error);
      message.error('Please check your inputs');
    }
  };

  const renderModelInputs = (provider, models) => (
    <Form.Item noStyle>
      {models.map((model, index) => (
        <SettingOption key={`${provider}_${index}`}>
          <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
            <div style={{ flex: 1 }}>
              <label style={{ 
                display: 'block', 
                marginBottom: '8px',
                color: 'var(--text-primary)'
              }}>
                Model Name
              </label>
              <Input 
                value={model.model}
                disabled
                style={{ 
                  backgroundColor: 'var(--bg-secondary)',
                  cursor: 'not-allowed'
                }}
              />
            </div>
            <Form.Item
              name={`${provider}_alias_${index}`}
              label="Alias"
              style={{ flex: 1 }}
              rules={[
                { 
                  required: true, 
                  message: 'Alias is required' 
                },
                {
                  pattern: /^@\w+$/,
                  message: 'Alias must start with @ and contain only letters and numbers'
                }
              ]}
            >
              <Input placeholder="Enter alias (e.g., @gpt4)" />
            </Form.Item>
          </div>
        </SettingOption>
      ))}
    </Form.Item>
  );

  const items = modelConfig ? [
    {
      key: 'openai',
      label: 'OpenAI',
      children: renderModelInputs('openai', modelConfig.openai)
    },
    {
      key: 'anthropic',
      label: 'Anthropic',
      children: renderModelInputs('anthropic', modelConfig.anthropic)
    },
    {
      key: 'gemini',
      label: 'Google Gemini',
      children: renderModelInputs('gemini', modelConfig.gemini)
    }
  ] : [];

  return (
    <div className="settings-section">
      <Form 
        form={form}
        layout="vertical"
        onValuesChange={handleValuesChange}
      >
        <SettingSection title="Model Configurations">
          <Tabs items={items} className="model-tabs" />
        </SettingSection>

        <div className="settings-footer">
          <Button 
            type="primary" 
            onClick={handleSave}
            disabled={!isFormChanged}
          >
            Save Changes
          </Button>
        </div>
      </Form>
    </div>
  );
};

export default ModelsSettings; 