import React from 'react';
import { Select, Switch } from 'antd';
import SettingSection from '../ui/SettingSection';
import SettingOption from '../ui/SettingOption';

const GeneralSettings = () => {
  return (
    <div className="settings-section">
      <SettingSection title="Language">
        <SettingOption>
          <Select
            defaultValue="en"
            style={{ width: 200 }}
            options={[
              { value: 'en', label: 'English' }
            ]}
            disabled
          />
        </SettingOption>
      </SettingSection>

      <SettingSection title="Notifications">
        <SettingOption label="Enable notifications">
          <Switch defaultChecked />
        </SettingOption>
      </SettingSection>
    </div>
  );
};

export default GeneralSettings; 