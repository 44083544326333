import { createContext, useState, useContext, useEffect } from 'react';

const FontContext = createContext({
  fontSize: 'medium',
  setFontSize: () => {},
});

const fontSizes = {
  small: {
    base: '14px',
    heading: '18px',
    title: '24px',
    icon: '18px'
  },
  medium: {
    base: '16px',
    heading: '20px',
    title: '32px',
    icon: '20px'
  },
  large: {
    base: '18px',
    heading: '24px',
    title: '36px',
    icon: '24px'
  }
};

export const useFontSize = () => {
  const context = useContext(FontContext);
  if (!context) {
    throw new Error('useFontSize must be used within a FontProvider');
  }
  return context;
};

export const FontProvider = ({ children }) => {
  const [fontSize, setFontSize] = useState(() => {
    return localStorage.getItem('fontSize') || 'medium';
  });

  useEffect(() => {
    // Apply font sizes to CSS variables
    document.documentElement.style.setProperty('--font-size-base', fontSizes[fontSize].base);
    document.documentElement.style.setProperty('--font-size-heading', fontSizes[fontSize].heading);
    document.documentElement.style.setProperty('--font-size-title', fontSizes[fontSize].title);
    document.documentElement.style.setProperty('--font-size-icon', fontSizes[fontSize].icon);
    localStorage.setItem('fontSize', fontSize);
  }, [fontSize]);

  return (
    <FontContext.Provider value={{ fontSize, setFontSize }}>
      {children}
    </FontContext.Provider>
  );
}; 