import React from 'react';
import { Tabs } from 'antd';
import AppearanceSettings from './sections/AppearanceSettings';
import GeneralSettings from './sections/GeneralSettings';
import PrivacySettings from './sections/PrivacySettings';
import ApiKeySettings from './sections/ApiKeySettings';
import ModelsSettings from './sections/ModelsSettings';

const Settings = ({ onClose }) => {
  const settingsItems = [
    {
      key: 'appearance',
      label: 'Appearance',
      children: <AppearanceSettings />
    },
    {
      key: 'api-keys',
      label: 'API Keys',
      children: <ApiKeySettings />
    },
    {
      key: 'models',
      label: 'Models',
      children: <ModelsSettings />
    },
    {
      key: 'general',
      label: 'General',
      children: <GeneralSettings />
    },
    {
      key: 'privacy',
      label: 'Privacy',
      children: <PrivacySettings />
    }
  ];

  return (
    <Tabs
      items={settingsItems}
      tabPosition="left"
      className="settings-tabs"
      style={{ minHeight: 400 }}
    />
  );
};

export default Settings; 