import { useState, useContext, useEffect } from 'react';
import { Layout, ConfigProvider, theme } from 'antd';
import Sidebar from './components/layout/Sidebar';
import MainLayout from './components/layout/MainLayout';
import { ThemeProvider, ThemeContext } from './contexts/ThemeContext';
import { FontProvider } from './contexts/FontContext';
import { ChatProvider } from './contexts/ChatContext';
import { initializeModelAliases } from './utils/modelConfig';
import './styles/layout.css';

const AppContent = () => {
  const [collapsed, setCollapsed] = useState(false);
  const { isDarkMode } = useContext(ThemeContext);

  useEffect(() => {
    // Initialize model aliases
    initializeModelAliases();
  }, []);

  // Apply theme to document
  useEffect(() => {
    document.documentElement.setAttribute('data-theme', isDarkMode ? 'dark' : 'light');
  }, [isDarkMode]);

  return (
    <ConfigProvider
      theme={{
        algorithm: isDarkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
        token: {
          colorPrimary: '#1677ff',
          borderRadius: 6,
        },
      }}
    >
      <Layout className="app-container">
        <Sidebar collapsed={collapsed} onCollapse={setCollapsed} />
        <MainLayout collapsed={collapsed} />
      </Layout>
    </ConfigProvider>
  );
};

const App = () => {
  return (
    <ThemeProvider>
      <FontProvider>
        <ChatProvider>
          <AppContent />
        </ChatProvider>
      </FontProvider>
    </ThemeProvider>
  );
};

export default App;
