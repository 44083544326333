import { message } from 'antd';
import Anthropic from '@anthropic-ai/sdk';

// API key configuration
const API_KEY_NAMES = {
  openai: 'openai_api_key',
  anthropic: 'anthropic_api_key',
  gemini: 'gemini_api_key'
};

// Helper functions
const getApiKey = (provider) => {
  return localStorage.getItem(API_KEY_NAMES[provider]);
};

const getProviderFromAlias = (alias) => {
  const savedModels = localStorage.getItem('model_aliases');
  if (!savedModels) return null;

  const models = JSON.parse(savedModels);
  for (const [provider, providerModels] of Object.entries(models)) {
    if (providerModels.some(model => model.alias === alias)) {
      return provider;
    }
  }
  return null;
};

const getModelFromAlias = (alias) => {
  const savedModels = localStorage.getItem('model_aliases');
  if (!savedModels) return null;

  const models = JSON.parse(savedModels);
  for (const [provider, providerModels] of Object.entries(models)) {
    const model = providerModels.find(model => model.alias === alias);
    if (model) {
      return model.model;
    }
  }
  return null;
};

// Initialize Anthropic client
let anthropicClient = null;
const getAnthropicClient = (apiKey) => {
  if (!anthropicClient || anthropicClient._options.apiKey !== apiKey) {
    anthropicClient = new Anthropic({ 
      apiKey,
      dangerouslyAllowBrowser: true
    });
  }
  return anthropicClient;
};

// API call functions
const callOpenAI = async (model, content) => {
  const apiKey = getApiKey('openai');
  if (!apiKey) {
    throw new Error('OpenAI API key not found');
  }

  // Prepare messages array with proper format for multimodal content
  const messages = [{
    role: 'user',
    content: []
  }];

  // Add text content if present
  if (content.text && content.text.trim()) {
    messages[0].content.push({
      type: 'text',
      text: content.text
    });
  }

  // Add image content if present
  if (content.files && content.files.length > 0) {
    const images = content.files.filter(file => file.type.startsWith('image/'));
    for (const image of images) {
      messages[0].content.push({
        type: 'image_url',
        image_url: {
          url: image.content
        }
      });
    }
  }

  // If no images, just send text as a simple string
  if (!content.files || content.files.length === 0) {
    messages[0].content = content.text;
  }

  const response = await fetch('https://api.openai.com/v1/chat/completions', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${apiKey}`
    },
    body: JSON.stringify({
      model,
      messages
    })
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(`OpenAI API error: ${error.error?.message || response.statusText}`);
  }

  const data = await response.json();
  return data.choices[0].message.content;
};

const callAnthropic = async (model, content) => {
  const apiKey = getApiKey('anthropic');
  if (!apiKey) {
    throw new Error('Anthropic API key not found');
  }

  try {
    const client = getAnthropicClient(apiKey);
    
    // Prepare message content for Claude-3
    const messageContent = [];

    // Add text content if present
    if (content.text && content.text.trim()) {
      messageContent.push({
        type: 'text',
        text: content.text
      });
    }

    // Add image content if present and model is Claude-3
    if (content.files && content.files.length > 0 && model.includes('claude-3')) {
      const images = content.files.filter(file => file.type.startsWith('image/'));
      for (const image of images) {
        messageContent.push({
          type: 'image',
          source: {
            type: 'base64',
            media_type: image.type,
            data: image.content.split(',')[1] // Remove the data:image/jpeg;base64, prefix
          }
        });
      }
    }

    const response = await client.messages.create({
      model,
      max_tokens: 1024,
      messages: [{
        role: 'user',
        content: messageContent.length > 0 ? messageContent : content.text
      }]
    });

    return response.content[0].text;
  } catch (error) {
    console.error('Anthropic API error:', error);
    throw new Error(`Anthropic API error: ${error.message}`);
  }
};

const callGemini = async (model, content) => {
  const apiKey = getApiKey('gemini');
  if (!apiKey) {
    throw new Error('Gemini API key not found');
  }

  // Prepare the contents array with parts
  const parts = [];

  // Add text content if present
  if (content.text && content.text.trim()) {
    parts.push({ text: content.text });
  }

  // Add image content if present
  if (content.files && content.files.length > 0) {
    const images = content.files.filter(file => file.type.startsWith('image/'));
    for (const image of images) {
      parts.push({
        inlineData: {
          mimeType: image.type,
          data: image.content.split(',')[1] // Remove the data:image/jpeg;base64, prefix
        }
      });
    }
  }

  const response = await fetch(`https://generativelanguage.googleapis.com/v1/models/${model}:generateContent?key=${apiKey}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify({
      contents: [{ parts }]
    })
  });

  if (!response.ok) {
    const error = await response.json();
    throw new Error(`Gemini API error: ${error.error?.message || response.statusText}`);
  }

  const data = await response.json();
  return data.candidates[0].content.parts[0].text;
};

// Main function to process message with multiple models
export const processWithModels = async (content, modelAliases) => {
  try {
    const responses = await Promise.all(modelAliases.map(async (modelAlias) => {
      try {
        const provider = getProviderFromAlias(modelAlias);
        const model = getModelFromAlias(modelAlias);

        if (!provider || !model) {
          return {
            model: modelAlias,
            content: `Invalid model alias: ${modelAlias}`,
            error: true
          };
        }

        let response;
        switch (provider) {
          case 'openai':
            response = await callOpenAI(model, content);
            break;
          case 'anthropic':
            response = await callAnthropic(model, content);
            break;
          case 'gemini':
            response = await callGemini(model, content);
            break;
          default:
            return {
              model: modelAlias,
              content: `Unsupported provider: ${provider}`,
              error: true
            };
        }

        return {
          model: modelAlias,
          content: response,
          error: null
        };

      } catch (error) {
        return {
          model: modelAlias,
          content: error.message || 'An error occurred while processing your request',
          error: true
        };
      }
    }));
    
    return responses;

  } catch (error) {
    // If there's a global error, return error responses for all models
    return modelAliases.map(modelAlias => ({
      model: modelAlias,
      content: error.message || 'An error occurred while processing your request',
      error: true
    }));
  }
}; 