import React from 'react';

const SettingOption = ({ label, children }) => {
  return (
    <div className="settings-option">
      {label && <span>{label}</span>}
      {children}
    </div>
  );
};

export default SettingOption; 