import React from 'react';
import { Switch, Radio } from 'antd';
import { useTheme } from '../../../contexts/ThemeContext';
import { useFontSize } from '../../../contexts/FontContext';
import SettingSection from '../ui/SettingSection';
import SettingOption from '../ui/SettingOption';

const AppearanceSettings = () => {
  const { isDarkMode, toggleTheme } = useTheme();
  const { fontSize, setFontSize } = useFontSize();

  return (
    <div className="settings-section">
      <SettingSection title="Theme">
        <SettingOption label="Dark mode">
          <Switch checked={isDarkMode} onChange={toggleTheme} />
        </SettingOption>
      </SettingSection>

      <SettingSection title="Font Size">
        <SettingOption>
          <Radio.Group 
            value={fontSize}
            onChange={(e) => setFontSize(e.target.value)}
          >
            <Radio.Button value="small">Small</Radio.Button>
            <Radio.Button value="medium">Medium</Radio.Button>
            <Radio.Button value="large">Large</Radio.Button>
          </Radio.Group>
        </SettingOption>
      </SettingSection>
    </div>
  );
};

export default AppearanceSettings;