import React, { createContext, useState, useContext } from 'react';

export const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState([]);
  const [activeModels, setActiveModels] = useState(new Set());
  const [loadingModels, setLoadingModels] = useState(new Set());
  const [modelErrors, setModelErrors] = useState(new Map());

  const addUserMessage = (content, mentionedModels) => {
    // Set loading state for mentioned models
    setLoadingModels(new Set(mentionedModels));

    // Create a unique timestamp for this message group
    const messageTimestamp = new Date();

    setMessages(prev => {
      const newMessages = [...prev];
      
      // Update or create threads for mentioned models
      mentionedModels.forEach(model => {
        const existingModelIndex = newMessages.findIndex(
          msg => msg.type === 'model' && msg.model === model
        );

        if (existingModelIndex !== -1) {
          // Add to existing conversation
          newMessages[existingModelIndex].conversation.push({
            role: 'user',
            content,
            timestamp: messageTimestamp
          });
        } else {
          // Create new thread for this model
          newMessages.push({
            type: 'model',
            model: model,
            conversation: [{
              role: 'user',
              content,
              timestamp: messageTimestamp
            }],
            timestamp: messageTimestamp
          });
        }
      });

      return newMessages;
    });

    // Update active models
    setActiveModels(new Set(mentionedModels));
  };

  const addModelResponses = (responses) => {
    setMessages(prev => {
      const newMessages = [...prev];
      responses.forEach(response => {
        const existingModelIndex = newMessages.findIndex(
          msg => msg.type === 'model' && msg.model === response.model
        );

        if (existingModelIndex !== -1) {
          // Add to existing thread
          newMessages[existingModelIndex].conversation.push({
            role: 'assistant',
            content: response.content,
            timestamp: new Date(),
            error: response.error
          });

          // Always remove from loading state, whether there's an error or not
          setLoadingModels(prev => {
            const next = new Set(prev);
            next.delete(response.model);
            return next;
          });
          
          // Update error state
          if (response.error) {
            setModelErrors(prev => new Map(prev).set(response.model, response.error));
          } else {
            setModelErrors(prev => {
              const next = new Map(prev);
              next.delete(response.model);
              return next;
            });
          }
        }
      });
      return newMessages;
    });
  };

  const removeModelThread = (modelId) => {
    setMessages(prev => prev.filter(msg => !(msg.type === 'model' && msg.model === modelId)));
    setActiveModels(prev => {
      const next = new Set(prev);
      next.delete(modelId);
      return next;
    });
    setLoadingModels(prev => {
      const next = new Set(prev);
      next.delete(modelId);
      return next;
    });
  };

  const clearMessages = () => {
    setMessages([]);
    setActiveModels(new Set());
    setLoadingModels(new Set());
    setModelErrors(new Map());
  };

  return (
    <ChatContext.Provider value={{ 
      messages, 
      addUserMessage, 
      addModelResponses, 
      removeModelThread,
      clearMessages,
      activeModels,
      loadingModels,
      modelErrors
    }}>
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within a ChatProvider');
  }
  return context;
}; 